<template>
    <div class="running-state">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: "running_state",
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.running-state {
    width: 100%;
    /* height: calc(100% - 40px);
    padding: 15px 85px; */
    box-sizing: border-box;
}
</style>

